<template>

	<div class="cont" id="p18">
		<div class="page-title">{{ program.name }}</div>

		<div class="c01">
			<div class="left">
				<div class="s01">
					<div class="c-item w100">
						<div class="title">등록일</div>
						<div class="content flex">
							<div class="cont-toggle">
								<div class="btn-toggle"
									:class="{ active: dateType == 'd'}"
									@click="setSearchDate('d')"
								>일간</div>
								<div class="btn-toggle"
									:class="{ active: dateType == 'w'}"
									@click="setSearchDate('w')"
								>주간</div>
								<div class="btn-toggle"
									:class="{ active: dateType == 'm'}"
									@click="setSearchDate('m')"
								>월간</div>
								<div class="btn-toggle"
									:class="{ active: dateType == 'c'}"
									@click="setSearchDate('c')"
								>선택</div>
							</div>
							<div class="cont-date">
								<div class="parent-datepicker">
									<input type="text" class="datepicker"
										placeholder="시작일"
										v-model="search.inquiryStartDate"
										@click="setSearchDate('s'); datePicker.start.view = true"
										readonly
									>
									<v-date-picker
										v-if="datePicker.start.view"
										v-model="search.inquiryStartDate"
										no-title
										scrollable
										style="position: absolute; top: 120px; left: 460px; border: 1px solid #bbb;"
										@change="datePicker.start.view = false"
									></v-date-picker>
								</div>
								<span>~</span>
								<div class="parent-datepicker">
									<input type="text" class="datepicker"
										placeholder="종료일"
										v-model="search.inquiryEndDate"
										@click="setSearchDate('e'); datePicker.end.view = true"
										readonly
									>
									<v-date-picker
										v-if="datePicker.end.view"
										v-model="search.inquiryEndDate"
										no-title
										scrollable
										style="position: absolute; top: 120px; left: 660px; border: 1px solid #bbb;"
										@change="datePicker.end.view = false"
									></v-date-picker>
								</div>
							</div>
						</div>
					</div>
					<div
						class="c-item"
						v-if="false"
					>
						<div class="title">정산 상태</div>
						<div class="content">
							<select name="" id=""
								v-model="search.settlementStatus"
								@change="getData"
							>
								<option 
									v-for="(settlement, index) in settlementStatus"
									:key="index"
									:value="settlement.code"
								>{{ settlement.codeName }}</option>
							</select>
						</div>
					</div>
				</div>
				<div class="s02">
					<a @click="getData"><div class="btn-search">조회</div></a>
					<a @click="clear"><div class="btn-reset">초기화</div></a>
					<a><div class="btn-excel">
						<vue-excel-xlsx
							:data="excel_items"
							:columns="columns"
							:filename="program.name"
							:sheetname="program.name"
						>
						엑셀 다운로드
						</vue-excel-xlsx>
					</div></a>
				</div>
			</div>
		</div>

		<div class="c02">
			<div class="table type07">
				<div class="text-right mb-10">
					<Pagination
						:options="search"
					/>
				</div>
				<div class="item-header">
					<div class="item">
						<div class="td">정산일</div>
						<div class="td">결제내역</div>
						<div class="td">결제금액</div>
						<div class="td">결제 수수료</div>
						<div class="td">부가세</div>
						<div class="td">정산금액</div>
						<div class="td">상태</div>
						<div class="td">상세내역</div>
					</div>
				</div>
				<div class="item-list">
					<template
						v-for="(item, index) in items"
					>
						<div class="item"
							:key="index">
							<div class="td">{{ item.approvalDate | transDate }}</div>
							<div class="td" style="justify-content: start !important;">총 {{ item.tradCount | makeComma }}건</div>
							<div class="td">{{ item.tradAmount | makeComma }}원</div>
							<div class="td">{{ item.tradFee | makeComma }}원</div>
							<div class="td">{{ item.tradFeeVat | makeComma }}원</div>
							<div class="td">{{ item.settlementAmount | makeComma }}원</div>
							<div class="td">{{ item.settlementStatus }}</div>
							<div class="td">
								<v-btn
									x-small
									@click="getDetail(index, item.approvalDate)"
									class="primary" 
								>
									<v-icon v-if="!item.details" small style="color: white !important;">mdi-menu-down</v-icon>
									<v-icon v-if="item.details" small style="color: white !important;">mdi-menu-up</v-icon>
								</v-btn>
							</div>
						</div>
						<template
							v-for="(detail) in item.details"
						>
							<div class="item bg-gray"
								:key="detail.approvalDate">
								<div class="td" style="justify-content: flex-end !important; padding-right: 10px;">
									<v-icon small>mdi-subdirectory-arrow-right</v-icon>
									{{ detail.approvalDate | transDateTime }}
								</div>
								<div class="td" style="justify-content: start !important;">{{ detail.productName }}</div>
								<div class="td">{{ detail.tradAmount | makeComma }}원</div>
								<div class="td">{{ detail.tradFee | makeComma }}원</div>
								<div class="td">{{ detail.tradFeeVat | makeComma }}원</div>
								<div class="td">{{ detail.settlementAmount | makeComma }}원</div>
								<div class="td">{{ detail.settlementStatus }}</div>
								<div class="td">
									<router-link
										class="btn-show_info" 
										:to="{ name: 'PaymentsHistoryDetail', params: { index: detail.tranSeq } }"
									>상세보기</router-link>
								</div>
							</div>
						</template>
						<div
							class="item"
							style="padding: 10px; justify-content: center;"
							v-if="item.details && item.page != item.total_count"
							:key="'detail_' + index"
						>
							<button
								type="button"
								class="success2"
								style="padding: 5px 10px; border-radius: 10px;"
								@click="getDetailAdd(index, item.approvalDate)"
							>더보기</button>
						</div>

					</template>
				</div>
				<div
					v-if="items.length <= 0"
					class="no-data"
				>
					No Data
				</div>
				<div class="text-right mt-10">
					<Pagination
						:options="search"
					/>
				</div>
			</div>

		</div>

		<SettlementPopupItem
			:item="item"
			:showPopup="showPopup.item"
			
			@setOverlay="setOverlay"
			@setNotify="setNotify"
		></SettlementPopupItem>
		
		<SettlementMerchantPaymentList
			v-if="showPopup.payment"
			:item="paymentItem"
			:user="user"
			
			@setOverlay="setOverlay"
			@setNotify="setNotify"
		></SettlementMerchantPaymentList>
	</div>
</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	import { date } from '@/resources/date/date'
	import { filter } from '@/resources/filter/filter'
	import { columns } from '@/resources/columns/columns'
	
	import SettlementPopupItem from '@/view/Settlement/Merchant/SettlementPopupItem'
	import SettlementMerchantPaymentList from '@/view/Settlement/Merchant/SettlementMerchantPaymentList'
	import Pagination from '@/components/Pagination'

	export default {
		name: 'PaymentsHistory'
		,props: ['user', 'codes']
		,components: { Pagination, SettlementPopupItem, SettlementMerchantPaymentList }
		,data: function(){
			return {
				program: {
					name: this.$language.menu[1].list[0].name
				}
				,agentSeq: this.user.basic.userSeq
				,items: [
				]
				,details: [
				]
				,columns: columns.Merchant.settlement
				,item: {
				}
				,search_default: {
					inquiryStartDate: date.getSearchBaseDate()
					,inquiryEndDate: date.getToday()
					,settlementStatus: ''
				}
				,search: {
					
				}
				,filter: filter
				,settlementStatus: this.codes.settlementStatus
				,datePicker: {
					start: {
						view: false
					}
					,end: {
						view: false
					}
				}
				,showPopup: {
					item: false
					,payment: false
				}
				,today: date.getToday()
				,dateType: 'd'
				,paymentItem: {}
				,search_size: 20
			}
		}
		,computed: {
			excel_items: function(){

				return this.items.filter(function(item){

					item.statusName = item.status == 'Y' ? '완료' : '대기'
					
					return item
				})
			}
		}
		,methods: {
			getData: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/settlement/summary2'
						,data: this.search
						,authorize: true
					})
					
					if(result.success){
						this.items = result.data.content.content
						
						this.$set(this.search, 'total_page', result.data.content.totalPages)
						this.$set(this.search, 'total_count', result.data.content.totalElements)
						this.$set(this.search, 'list_cnt', result.data.content.size)
						
						//await this.excel()
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,goDetail: function(pgMerchNo){
				this.$router.push({ to: 'PaymentsHistory', params: { payChnCate: pgMerchNo}})
			}
			,clear: function(){
				this.setSearchDate('c')
				this.search = {
					inquiryStartDate: date.getSearchBaseDate()
					,inquiryEndDate: date.getToday()
					,keyword: ''
					,settlementStatus: ''
				}
			}
			,excel: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/settlements/merchants/download'
						,data: {
							inquiryStartDate: date.getSearchBaseDate()
						}
						,authorize: true
					})

					if(result.success){
						this.excel_items = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.data.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,setSearchDate: async function(type){
				this.datePicker.start.view = false
				this.datePicker.end.view = false
				if(type == 'd'){
					this.search.inquiryStartDate = this.today
					this.search.inquiryEndDate = this.today
				}else if(type == 'w'){
					let dates = date.getWeeklyDate()
					this.search.inquiryStartDate = dates.start
					this.search.inquiryEndDate = dates.end
				}else if(type == 'm'){
					let dates = date.getMonthlyDate()
					this.search.inquiryStartDate = dates.start
					this.search.inquiryEndDate = dates.end
				}else if(type == 's'){
					this.search.inquiryStartDate = ''
					type = 'c'
				}else if(type == 'e'){
					this.search.inquiryEndDate = ''
					type = 'c'
				}else{
					this.search.inquiryStartDate = date.getSearchBaseDate()
					this.search.inquiryEndDate = date.getToday()
				}

				this.dateType = type

				if(this.search.inquiryStartDate && this.search.inquiryEndDate) {
					await this.getData()
				}
			}
			,showPaymet: function({ expPayDt, pgMerchNo, pgTid }){
				this.showPopup.payment = true
				this.paymentItem.expPayDt = expPayDt
				this.paymentItem.pgMerchNo = pgMerchNo
				this.paymentItem.pgTid = pgTid
				this.$emit('setOverlay', true)
			}
			,setOverlay: function(){
				this.showPopup.item = false
				this.showPopup.payment = false
				this.$emit('setOverlay', false)
			}
			,setNotify: function({ type, message}){
				this.$emit('setNotify', { type: type, message: message })
			}
			,getDetail: async function(item_index, inquiryDate){
				if(this.items[item_index].details){
					this.$delete(this.items[item_index], 'details')
					return false
				}
				try{
					const result = await Axios({
						method: 'get'
						,url: '/settlement/history2'
						,data: {
							settlementDate: inquiryDate
							,size: this.search_size
						}
						,authorize: true
					})
					
					if(result.success){
						this.$set(this.items[item_index], 'details', result.data.content.content)
						this.$set(this.items[item_index], 'page', 1)
						this.$set(this.items[item_index], 'total_count', result.data.content.totalPages)
						console.log(this.items[item_index])
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,getDetailAdd: async function(item_index, inquiryDate){
				console.log(this.items[item_index])
				try{
					const result = await Axios({
						method: 'get'
						,url: '/settlement/history2'
						,data: {
							settlementDate: inquiryDate
							,size: this.search_size
							,page: this.items[item_index].page + 1
						}
						,authorize: true
					})

					if(result.success){
						for(let i = 0; i < result.data.content.content.length; i++) {
							this.items[item_index].details.push(result.data.content.content[i])
						}
						this.$set(this.items[item_index], 'page', this.items[item_index].page + 1)
						this.$set(this.items[item_index], 'total_count', result.data.content.totalPages)
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
		}
		,created: async function(){
			this.clear()
		}
		,watch: {
			'search.page': {
				handler: function(){
					this.getData()
				}
			}
			,'search.inquiryStartDate': {
				handler: function(call){
					this.search.inquiryStartDate = call.replaceAll('-', '')
				}
			}
			,'search.inquiryEndDate': {
				handler: function(call){
					this.search.inquiryEndDate = call.replaceAll('-', '')
				}
			}
		}
	}
</script>

<style>
	.table.type07 .td:nth-child(1) { width: 15% !important; justify-content: center !important;}
	.table.type07 .td:nth-child(2) { width: 25% !important; justify-content: center !important;}
	.table.type07 .td:nth-child(3) { width: 10% !important; justify-content: center !important;}
	.table.type07 .td:nth-child(4) { width: 10% !important; justify-content: center !important;}
	.table.type07 .td:nth-child(5) { width: 10% !important; justify-content: center !important;}
	.table.type07 .td:nth-child(6) { width: 10% !important; justify-content: center !important;}
	.table.type07 .td:nth-child(7) { width: 10% !important; justify-content: center !important;}
	.table.type07 .td:nth-child(8) { width: 10% !important; justify-content: center !important;}
	
	.bg-gray { background-color: #eee !important;}
</style>





















